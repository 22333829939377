<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <e-commerce-checkout-step-cart-products
      :product-in-cart="productInCart"
      :is-editable="true"
      @refresh-cart="$emit('refresh-cart')"
    />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>

        <label class="section-label mb-1">Options</label>
        <b-input-group class="input-group-merge coupons">
          <b-form-input placeholder="Coupons" />
          <b-input-group-append is-text>
            <span
              id="input-coupons"
              class="input-group-text text-primary cursor-pointer"
            >Apply</span>
          </b-input-group-append>
        </b-input-group>
        <hr>
        <div class="price-details">
          <h6 class="price-title">
            Price Details
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Total MRP
              </div>
              <div class="detail-amt">
                ${{ subtotalPrice }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Bag Discount
              </div>
              <div class="detail-amt discount-amt text-success">
                $0
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Estimated Tax
              </div>
              <div class="detail-amt">
                $0
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                EMI Eligibility
              </div>
              <a
                href="javascript:void(0)"
                class="detail-amt text-primary"
              >Details</a>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Delivery Charges
              </div>
              <div class="detail-amt discount-amt text-success">
                Free
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Total
              </div>
              <div class="detail-amt font-weight-bolder">
                ${{ totalPrice }}
              </div>
            </li>
          </ul>

          <b-button
            v-if="!$isEmpty(productInCart)"
            variant="primary"
            block
            @click="$emit('next-step')"
          >
            Place Order
          </b-button>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './MarketplaceCheckoutStepCartProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  props: {
    productInCart: {
      type: Array,
      default: () => []
    },
    subtotalPrice: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>
