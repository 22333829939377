<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >

      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Add New Address</b-card-title>
          <b-card-text class="text-muted mt-25">
            Be sure to check "Deliver to this address" when you have finished
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <div class="mx-1 mb-3">
            <b-row>
              <h5>
                Who is the purchase for?
              </h5>
            </b-row>

            <b-row>
              <b-form-radio
                v-model="delivery.purchase_made_for"
                class="col ml-1"
                value="self"
              >Self</b-form-radio>
              <b-form-radio
                v-model="delivery.purchase_made_for"
                class="col ml-1"
                value="company"
              >Company</b-form-radio>
            </b-row>
          </div>

          <hr>

          <!-- <b-row v-if="purchase_made_for === 'self'"> -->
          <b-row v-if="false">
            <!-- Full Name -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Full Name"
                rules="required"
              >
                <b-form-group
                  label="Full Name"
                  label-for="full-name"
                  class="mb-2"
                >
                  <b-form-input
                    id="full-name"
                    v-model="addressDetails.fullName"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Mobile Number -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Mobile Number"
                rules="required|integer"
              >
                <b-form-group
                  label="Mobile Number"
                  label-for="mobile-number"
                >
                  <b-form-input
                    id="mobile-number"
                    v-model="addressDetails.mobile"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Flat House No -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Flat, House No"
                rules="required"
              >
                <b-form-group
                  label="Flat, House No"
                  label-for="flat-house-no"
                  class="mb-2"
                >
                  <b-form-input
                    id="flat-house-no"
                    v-model="addressDetails.houseNo"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Landmark -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Landmark"
                rules="required"
              >
                <b-form-group
                  label="Landmark"
                  label-for="landmark"
                  class="mb-2"
                >
                  <b-form-input
                    id="landmark"
                    v-model="addressDetails.landmark"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- City -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Town/city"
                rules="required"
              >
                <b-form-group
                  label="Town/City"
                  label-for="city"
                  class="mb-2"
                >
                  <b-form-input
                    id="city"
                    v-model="addressDetails.city"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- pincode -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Pincode"
                rules="required|integer"
              >
                <b-form-group
                  label="Pincode"
                  label-for="pincode"
                  class="mb-2"
                >
                  <b-form-input
                    id="pincode"
                    v-model="addressDetails.pincode"
                    type="number"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- state -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="State"
                rules="required"
              >
                <b-form-group
                  label="State"
                  label-for="state"
                  class="mb-2"
                >
                  <b-form-input
                    id="state"
                    v-model="addressDetails.state"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Address Type -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Address Type"
                rules="required"
              >

                <b-form-group
                  label="Address Type"
                  label-for="address-type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="addressDetails.addressType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['Home', 'Work']"
                    input-id="address-type"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
              >
                Save and Deliver Here
              </b-button>
            </b-col>
          </b-row>

          <b-row
            class="w-100 mt-2"
          >
            <!-- Fullname -->
            <b-col
              v-if="delivery.purchase_made_for === 'self'"
              cols="6"
              class="mb-1"
            >
              <h6 class="">
                Full Name
              </h6>
              <b-form-input
                v-model="delivery.recipient"
                required
                class="w-full"
                :danger="
                  delivery.recipient
                    && delivery.recipient.length > 1 ? false : true
                "
                danger-text="You need to add a valid address."
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>

            <!-- company name -->
            <b-col
              v-if="delivery.purchase_made_for === 'employer'"
              cols="6"
              class="mb-1"
            >
              <h6 class="">
                Company Name
              </h6>
              <b-form-input
                v-model="delivery.recipient"
                required
                :disabled="true"
                class="w-full"
                :danger="
                  delivery.recipient
                    && delivery.recipient.length > 1 ? false : true
                "
                danger-text="You need to add a valid address."
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>

            <!-- Phone Number -->
            <b-col
              cols="6"
              class="mb-1"
            >
              <h6 class="">
                Phone Number
              </h6>
              <b-form-input
                v-model="delivery.phone_number"
                required
                type="number"
                class="w-full"
                :danger="
                  delivery.phone_number
                    && delivery.phone_number.length < 8 ? false : true
                "
                danger-text="You need to add a valid address."
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>

            <!-- address -->
            <b-col
              cols="6"
              class="mb-1"
            >
              <h6 class="">
                Delivery Address
              </h6>
              <b-form-input
                v-model="delivery.address"
                required
                class="w-full"
                :danger="
                  delivery.address
                    && delivery.address.length > 1 ? false : true
                "
                danger-text="You need to add a valid address."
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>

            <!-- state -->
            <b-col
              cols="6"
              class="mb-1"
            >
              <h6 class="">
                Delivery State
              </h6>
              <b-form-input
                v-model="delivery.state"
                required
                class="w-full"
                :danger="
                  delivery.state
                    && delivery.state.length > 1 ? false : true
                "
                danger-text="You need to add a valid state."
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>

            <!-- country -->
            <b-col
              cols="6"
              class="mb-1"
            >
              <h6 class="">
                Delivery Country
              </h6>

              <v-select
                v-model="delivery.country"
                placeholder="Choose the country where the job is located in"
                autocomplete
                class="w-full"
                :options="allCountries"
                label="value"
                :reduce="value => value.value"
              />
            </b-col>

            <!-- postal code -->
            <b-col
              cols="6"
              class="mb-1"
            >
              <h6 class="">
                Delivery Postal Code
              </h6>
              <b-form-input
                v-model="delivery.postal_code"
                required
                class="w-full"
                :danger="
                  delivery.postal_code
                    && delivery.postal_code.length > 1 ? false : true
                "
                danger-text="You need to add a valid postal code."
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>

            <b-button
              variant="primary"
              @click="validateDeliveryAddressForm"
            >
              Save and Deliver Here
            </b-button>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Right Col -->
      <div class="customer-card" style="height: 65vh; overflow-y: auto;">
        <template v-for="address in deliveryAddresses">
          <b-card :key="address.key" :title="address.recipient">
            <b-card-text class="mb-0">
              {{ address.address }}
            </b-card-text>
            <b-card-text>{{ address.state }}</b-card-text>
            <b-card-text>{{ address.country }}</b-card-text>
            <b-card-text>{{ address.postal_code }}</b-card-text>
            <b-card-text>{{ address.phone_number }}</b-card-text>

            <b-row>
              <b-col>
                <b-button
                  variant="primary"
                  block
                  @click="$emit('deliver-to-this-address', address)"
                >
                  Deliver To This Address
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  variant="danger"
                  block
                  @click="confirmDeleteDeliveryAddress(address._id)"
                >
                  Delete
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  allCountries,
  highestQualifications,
} from '@/constants/datastore'

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deliveryAddresses: [],

      purchase_made_for: 'self',
      delivery: {
        purchase_made_for: null,
      },

      allCountries: [],
    }
  },
  watch: {
    'delivery.purchase_made_for': function(newVal) {
      if (newVal === "company") {
        this.delivery.recipient = this.$store.state.auth.ActiveUser.company_name
      } else {
        this.delivery.recipient = this.$store.state.auth.ActiveUser.fullname
      }
    },
  },
  created() {
    this.allCountries = _.orderBy(allCountries, ['value'], ['asc'])
    this.get_delivery_addresses()
  },
  mounted() {
    this.delivery.purchase_made_for = 'self'
    this.delivery.country = 'Singapore'
  },
  methods: {
    get_delivery_addresses() {
      this.$http
        .get(`/api/marketplace/delivery-addresses`)
        .then(response => {
          const { success, output } = response.data
          if (success) {
            this.deliveryAddresses = output
          }
        })
        .catch(error => {
          //
        })
    },

    confirmDeleteDeliveryAddress(id) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete this address?`,
          {
            title: 'Confirm Delete Delivery Address',
            size: 'sm',
            okTitle: 'Delete',
            okVariant: 'danger',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.deleteDeliveryAddress(id)
          }
        })
    },

    deleteDeliveryAddress(id) {
      this.$http
        .delete(`/api/marketplace/delivery-addresses/${id}`)
        .then(response => {
          const { success, output, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Successful',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.get_delivery_addresses()
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'AlertCircleIcon',
                  text: 'Unable to delete delivery address, please try again later',
                  variant: 'error',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(() => {})
    },

    saveDeliveryAddress(event) {
      this.$http
        .post(`/api/marketplace/delivery-addresses`, {
          form_data: event,
        })
        .then(response => {
          const { success, output, message } = response.data

          if (success) {
            this.delivery = output

            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.get_delivery_addresses()
            this.$emit('deliver-to-this-address', this.delivery)

            this.formWizardNextStep()
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'AlertCircleIcon',
                  text: 'Failed saving delivery address, please try again',
                  variant: 'error',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(() => {})
    },

    validateDeliveryAddressForm() {
      let message = ''

      if (this.$isEmpty(this.delivery.recipient)) {
        message += 'A valid Name is required\n'
      }

      if (!this.$checkPhoneNumber(this.delivery.phone_number).state) {
        message += 'A valid Phone Number is required\n'
      }

      if (this.$isEmpty(this.delivery.address)) {
        message += 'A valid Delivery Address is required\n'
      }

      if (this.$isEmpty(this.delivery.state)) {
        message += 'A valid Delivery State is required\n'
      }

      if (this.$isEmpty(this.delivery.country)) {
        message += 'Please select a Delivery Country'
      }

      if (this.$isEmpty(this.delivery.postal_code)) {
        message += 'A valid Delivery Postal Code is required\n'
      }

      if (this.$isEmpty(message)) {
        this.saveDeliveryAddress(this.delivery)
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Incomplete Form',
              icon: 'AlertCircleIcon',
              text: message,
              variant: '',
              bodyClass: 'text-break',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
  },
  setup(_, { emit }) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const onSubmit = () => {
      emit('next-step')
    }

    return {

      // Form
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
